<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="historyData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching reports history data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No reports found with this id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'daily-reports-history'}"
        >
          {{ $t('Notifications List') }}
        </b-link>
        {{ $t('for other notifications') }}.
      </div>
    </b-alert>

    <template v-if="historyData">
      <notification-history-card :report="historyData" />
    </template>

  </div>
</template>

<script>

import {
  BAlert, BLink,
} from 'bootstrap-vue'
import NotificationHistoryCard from '@/views/pages/notification-history/NotificationHistoryCard.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BAlert,
    BLink,

    // Local Components
    NotificationHistoryCard,

  },
  setup() {
    const historyData = ref(null)

    store.dispatch('tenders/fetchDailyReportHistorySingle', router.currentRoute.params.id)
      .then(response => { historyData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          historyData.value = undefined
        }
      })

    return {
      historyData,
    }
  },
}
</script>

<style>

</style>
