<template>
  <b-card no-body>
    <b-card-header
      v-if="report.email"
      class="email-detail-head pb-0"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <div class="mail-items">
          <h5 class="mb-0">
            {{ report.email }}
          </h5>
          <span class="font-small-3 text-muted mr-25">{{ report.company_name }}</span>
        </div>
      </div>
      <div class="mail-meta-item">
        <div class="font-weight-bold">
          {{ $t('Created at') }}: {{ formatDate(report.created_at) }}
        </div>
        <div class="font-weight-bold">
          {{ $t('Sent at') }}: {{ formatDate(report.notification_send_at) }}
        </div>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper">
      <div
        class="mail-message"
      >
        <div
          v-for="item in report.data"
          :key="item.id"
        >
          <h5 class="mt-2 mb-2">
            <span>{{ $t('Search profile') }}: </span><span class="text-primary"><b>{{ item.profile_name }}</b></span>
          </h5>

          <div v-if="item.data.length === 0">
            <p>{{ $t('No tenders email send.') }}</p>
          </div>

          <div
            v-for="data in item.data"
            v-else
            :key="data.id"
          >
            <table class="border-x">
              <tr>
                <td style="width: 200px">
                  {{ $t('Subscriber') }}
                </td>
                <td>{{ data.contract_authority_name }}</td>
              </tr>
              <tr>
                <td>{{ $t('Title') }}</td>
                <td>{{ data.title }}</td>
              </tr>
              <tr>
                <td>{{ $t('Document type') }}</td>
                <td>{{ data.document_type }}</td>
              </tr>
              <tr>
                <td>{{ $t('Address') }}</td>
                <td>{{ data.address }}</td>
              </tr>
              <tr>
                <td>
                  URL
                </td>
                <td>
                  <a
                    :href="data.url"
                    target="_blank"
                  >{{ data.url }}</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- eslint-enable -->
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
}
</script>

<style>
td {
  padding: 3px
}

.border-x {
  border-top: 1px solid #d0d3d7;
  width: 100%;
  margin-bottom: 10px;
  border-spacing: 10px;
  border-collapse: separate;
}
</style>
